import styled from "styled-components";
import { ReactComponent as SDownload } from "../../../SVG/download.svg";
import "../../../Animations.css";
import { Box } from "@mui/material";
import { GitHub as SGitHub } from "../../About/SC/AboutSC";

export const Hero = styled.div`
  display: flex;
  flex-direction: column;
  width: 56.625rem;
  font-size: 1.3rem;
  font-family: Inter;
  margin: 0 auto 30px auto;
  align-items: center;
  @media (max-width: 824px) {
    width: 90%;
    font-size: 1.2rem;
    text-align: center;
  }
`;

export const Button = styled.div`
  display: flex;
  justify-content: center;
  width: 75px;
  padding: 10px 20px;
  border: 1px solid #e2e7f0;
  border-radius: 6px;
  font-size: 20px;
  height: fit-content;
  cursor: pointer;
  transition: all 150ms;
  &:hover {
    color: #1a202c;
    background-color: #e2e7f0;
  }
`;

export const Download = styled(Button)`
  width: 100px;
  @media (max-width: 824px) {
    display:none;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  height: fit-content;
  align-items:center;
  gap: 30px;
  @media (max-width: 824px) {
    gap: 1rem;
    display:none;
  }
`;

export const MobileButtonGroup = styled(ButtonGroup)`
  display:none;
  @media (max-width:824px) {
    display:flex;
  }
`

export const TitleBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 56.625rem;
  margin: 40px auto 0px auto;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 824px) {
    flex-direction: column;
    width: 100%;
  }
`;

export const Title = styled(Box)`
  font-family: Inter Bold;
  font-size: 3.1rem;
  @media (max-width: 824px) {
    font-size: 2.1rem;
    text-decoration: underline;
    cursor:pointer;
    display:none;
  }
`;

export const MobileTitle = styled(Title)`
  display:none;
  @media (max-width:824px) {
    display:block;
  }
`

export const Libs = styled.div`
  display: flex;
  width: 56.625rem;
  line-height: 1.6;
  flex-wrap: wrap;
  @media (max-width: 824px) {
    margin-top:5px;
    width: 100%;
    justify-content: center;
  }
`;

export const Link = styled.div`
  text-decoration: underline;
  overflow-wrap: break-word;
  cursor: pointer;
  transition: all 150ms;
  &:hover {
    transform: scale(1.02);
  }
  &:active {
    color: #319deb;
  }
`;

export const DownloadSVG = styled(SDownload)`
  height: ${({ height }) => height || "30px"};
  cursor:pointer;
  display:none;
  width: ${({ width }) => width || "30px"};
  border: 2px solid #e2e7f0;
  border-radius:8px;
  padding:2px;
  & path {
    fill: ${({ color }) => color || "#e2e7f0"};
    
  }
  & path:hover {
    fill: ${({ color }) => color || "#319deb"};
  }
  &:hover {
  border: 2px solid #319deb;
  }
  @media (max-width:824px) {
    display:block;
  }
`;

export const Reminder = styled.div`
  margin-top:20px;
  font-size:0.9rem;
  display:none;
  @media (max-width:824px) {
    display:block;
  }
`

export const GitHub = styled(SGitHub)`
@media (max-width:824px) {
  display:none;
}
`

export const ExtraImage = styled.img`
max-width:56.625rem;
max-height:25rem;
@media (max-width:824px) {
  max-width:70%;
  max-height:15rem;

}

`