import React, { useContext } from "react";
import {
  Container,
  Welcome,
  Button,
  HeroText,
  Link,
  WelcomeMobile,
} from "../SC/HomeSC";
import { ReferenceDataContext } from "../../../Data/ContextProvider";
import { GitHub, Instagram, LinkedIn, MobileIcons } from "../../About/SC/AboutSC";


export const Home = () => {
  const {language} = useContext(ReferenceDataContext);
  return (
      <Container>
        <HeroText >
          <Welcome>{language ? "Zdravo, ja sam Maky" : "Hello, I'm Maky"}</Welcome>
          <WelcomeMobile>{language ? "Zdravo, ja sam Maky" : "Hello, I'm Maky"}</WelcomeMobile>
          {language ? "Pogledajte moje nedavne radove" : "Check out my recent work"}
          <Link to="/projects">
            <Button>{language ? "Projekti" : "Projects"}</Button>
          </Link>
          <MobileIcons>
          <LinkedIn
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/maky1x/",
                "_blank",
                "noreferrer"
              )
            }
          />
          <GitHub
            onClick={() =>
              window.open("https://github.com/maky1x", "_blank", "noreferrer")
            }
          />
          <Instagram
            onClick={() =>
              window.open(
                "https://www.instagram.com/makineskovic/",
                "_blank",
                "noreferrer"
              )
            }
          />
        </MobileIcons>
        </HeroText>
      </Container>
  );
};
