import { Box } from "@mui/material";
import { Link as RLink } from "react-router-dom";
import styled from "styled-components";

export const Card = styled(Box)`
  width: 256px;
  height: 256px;
  border: 3px solid #e2e7f0;
  border-radius: 8px;
  display: flex;
  background-opacity: 0.5;
  background-image: ${({ img }) => `url(/${img})`};
  background-repeat:no-repeat;
  background-size:cover;
  cursor: pointer;
  transition: all 150ms;
  @media (max-width: 824px) {
    width: 128px;
    height: 128px;
    font-size: 2rem;
    background-image: ${({ mobileImg }) => `url(/${mobileImg})`};
  }
`;

export const Image = styled.img`
  height: 100%;
  width: 15vw;
  padding-right: 15px;
`;

export const Name = styled(Box)`
  font-family: Inter SemiBold;
  transition: all 150ms;
  white-space: nowrap;
  @media (max-width: 824px) {
    display:none;
  }
`;

export const MobileName = styled(Name)`
  display:none;
  @media (max-width: 824px) {
    display:block;
    font-size:0.9rem;
  }
`;

export const Desc = styled(Box)`
  font-size: 1.1rem;
  font-family: Inter Medium;
  transition: all 150ms;
  @media (max-width: 824px) {
    font-size: 0.8rem;
  }
`;

export const Gradient = styled(Box)`
  height: 256px;
  position: absolute;
  width: 256px;
  background-image: linear-gradient(
    to top,
    rgba(26, 32, 44, 1),
    rgba(26, 32, 44, 0)
  );
  transition: all 150ms;
  z-index: 1;
  @media (max-width: 824px) {
    width:128px;
    height:128px;
  }
`;

export const HeroText = styled(Box)`
  margin-top: 78%;
  height: 20%;
  width: 100%;
  text-align: center;
  z-index: 11;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 150ms;
  @media (max-width: 824px) {
    margin-top:70%;
  }
`;

export const Title = styled.div`
  font-family: Inter Bold;
  font-size: 2.5rem;
  width: 56.625rem;
  margin: 40px auto 30px auto;
  @media (max-width: 824px) {
    width: 67%;
    text-align: center;
    margin: 40px auto 30px auto;
    font-size:2rem;

  }
  @media (max-width:428px) {
    width:95%;
  }
  @media (max-width:1198px) and (min-width:824px) {
    width:43rem;
  }
`;

export const Cards = styled.div`
  width: 56.625em;
  display: grid;
  justify-content:center;
  grid-template-columns: repeat(auto-fill, 256px);
  gap: 69px;
  margin: 50px auto 30px auto;
  @media (max-width: 824px){
    column-gap:1rem;
    row-gap: 2rem;
    width: 67%;
    margin: 20px auto 30px auto;
    grid-template-columns: repeat(auto-fill, 128px);
  }
  @media (max-width:428px) {
    width:95%;
  }
`;

export const Link = styled(RLink)`
  width:256px;
  height:256px;
  @media (max-width:824px) {
    width:128px;
    height:128px;
  }
`;
