import styled from "styled-components";
import { ReactComponent as SLinkedIn } from "../../../SVG/linkedin.svg";
import { ReactComponent as SInstagram } from "../../../SVG/instagram.svg";
import { ReactComponent as SGitHub } from "../../../SVG/github.svg";
import { Container as HomeContainer } from "../../Home/SC/HomeSC";

export const Page = styled(HomeContainer)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 824px) {
  }
`;

export const HeroText = styled.div`
  width: 56.625rem;
  font-size: 1.3rem;
  height: auto;
  font-family: Inter;
  margin: 50px auto 30px auto;
  @media (max-width: 824px) {
    width: 90%;
    margin: 30px auto 30px auto;
    font-size: 1.2rem;
    text-align: center;
  }
`;

export const Icons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
  @media (max-width: 824px) {
    width: 100%;
  display:none;
  }
`;

export const MobileIcons = styled(Icons)`
display:none;
  margin-top: 10px;
  @media(max-width:824px) {
    display:flex;
  }
`

export const LinkedIn = styled(SLinkedIn)`
  transition: all 150ms;
  cursor: pointer;
  height: ${({ height }) => height || "48px"};
  width: ${({ width }) => width || "48px"};
  & path {
    fill: ${({ color }) => color || "#e2e7f0"};
  }
  &:hover path {
    fill: ${({ hoverColor }) => hoverColor || "#319deb"};
  }
  &:hover {
    transform: scale(1.3);
  }
`;

export const Instagram = styled(SInstagram)`
  transition: all 150ms;
  cursor: pointer;
  height: ${({ height }) => height || "48px"};
  width: ${({ width }) => width || "48px"};
  & path {
    fill: ${({ color }) => color || "#e2e7f0"};
  }
  &:hover path {
    fill: ${({ hoverColor }) => hoverColor || "#319deb"};
  }
  &:hover {
    transform: scale(1.3);
  }
`;

export const GitHub = styled(SGitHub)`
  transition: all 150ms;
  cursor: pointer;
  height: ${({ height }) => height || "48px"};
  width: ${({ width }) => width || "48px"};
  & path {
    fill: ${({ color }) => color || "#e2e7f0"};
  }
  &:hover path {
    fill: ${({ hoverColor }) => hoverColor || "#319deb"};
  }

  &:hover {
    transform: scale(1.3);
  }
`;

export const Paragraph = styled.p`
  margin-bottom: 15px;
  font-family: Inter;
`;
