import { Box } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  width: 100vw;
  height: 80px;
  max-height: 90px;
  display: flex;
  align-items: center;
  font-family: Inter SemiBold;
  border-bottom: 2px solid #e2e7f0;
`;

export const ButtonGroup = styled.div`
  width: 20rem;
  height: 80px;
  display: flex;
  gap: 4rem;
  justify-content: center;
  align-items: center;
  @media (max-width: 824px) {
    gap:3rem;
    width: 100%;
  }
  @media (max-width:428px) {
    gap:2.5rem;
  }
`;

export const Button = styled(Box)`
  ${({ color }) => `
    font-size: 1.1rem;
    color: ${color};
    cursor: pointer;
    width:fit-content;
    white-space:nowrap;
    transition: all 150ms;
    &:hover {
      color: #319deb;
    }
  `}
`;

export const Profile = styled.div`
  display: flex;
  width: 250px;
  align-items: center;
  font-size: 1.1rem;
  @media (max-width: 824px) {
    display: none;
  }
`;

export const ProfileIcon = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 10px;
`;

export const Spacing = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-left: 10%;
  @media (max-width: 824px) {
    padding-left: 0;
  }
`;

export const Language = styled.div`
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  align-items: center;
  margin-left:50px;
  @media(max-width:824px) {
    display:none;
  }
`;

export const LanguageMobile = styled(Language)`
display:none;
font-size:1.1rem;
margin-left:0;
color:#e2e7f0;
transition: all 150ms;
&:hover {
  cursor:pointer;
}
@media (max-width:824px) {
  display:flex;
}
`

export const Flag = styled.img`
height:20px;
width:28px;
transition: all 150ms;
cursor:pointer;
&:hover {
  transform:scale(1.2);
}
`

