import React, { useContext, useState } from "react";
import {
  ButtonGroup,
  Download,
  DownloadSVG,
  ExtraImage,
  GitHub,
  Hero,
  Libs,
  Link,
  MobileButtonGroup,
  MobileTitle,
  Reminder,
  Title,
  TitleBox,
} from "../SC/ProjectInfoSC";
import { useLocation } from "react-router-dom";
import { ReferenceDataContext } from "../../../Data/ContextProvider";

export const ProjectInfo = () => {
  const [hover, setHover] = useState(false);
  const { language } = useContext(ReferenceDataContext);
  const location = useLocation();
  const data = location.state.data;
  return (
    <Hero>
      <TitleBox>
        <MobileButtonGroup>
          <MobileTitle
            onClick={() => window.open(data.github, "_blank", "noreferrer")}
          >
            {language ? data.serbian.fullName : data.fullName}
          </MobileTitle>
          {data.downloadLink !== "" && (<DownloadSVG
            height={30}
            width={30}
            onClick={() =>
              window.open(data.downloadLink, "_blank", "noreferrer")
            }
          />)}
        </MobileButtonGroup>
        <Title>{language ? data.serbian.fullName : data.fullName}</Title>
        <ButtonGroup>
          <GitHub
            height={49}
            width={49}
            onClick={() => window.open(data.github, "_blank", "noreferrer")}
          />

          {data.downloadLink !== "" && (
            <Download
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              onClick={() =>
                window.open(data.downloadLink, "_blank", "noreferrer")
              }
            >
              {!hover ? (language ? "Preuzmi" : "Download") : data.downloadSize}
            </Download>
          )}
        </ButtonGroup>
        <Reminder>
          *
          {language
            ? "Kliknite naslov da biste dosli do GitHub stranice"
            : "Click the title to access the GitHub repository"}
        </Reminder>
      </TitleBox>
      <Libs>
        {data?.libs?.toString() !== ""
          ? language
            ? "Biblioteke:"
            : "Libraries:"
          : ""}
        {data?.libs?.map((lib, index) => (
          <>
            {index !== 0 ? "," : ""}&nbsp;
            <Link onClick={() => window.open(lib.url, "_blank", "noreferrer")}>
              {lib.name}
            </Link>
          </>
        ))}
      </Libs>
      <div>
        {!language
          ? data?.description?.map((paragraph) => <p>{paragraph}</p>)
          : data?.serbian?.description?.map((paragraph) => <p>{paragraph}</p>)}
      </div>
      {!language && (
        <>
          {" "}
          {data?.extras?.map((extra) => (
            <>
              {extra.img !== "" ? <ExtraImage src={`${process.env.PUBLIC_URL}/${extra.img}`} alt="" /> : <></>}
              <p>{extra.text}</p>
            </>
          ))}
        </>
      )}
      {language && (
        <>
          {" "}
          {data?.serbian?.extras?.map((extra) => (
            <>
            {extra.img !== "" ? <ExtraImage src={`${process.env.PUBLIC_URL}/${extra.img}`} alt="" /> : <></>}
            <p>{extra.text}</p>
          </>
          ))}
        </>
      )}
    </Hero>
  );
};
