export const data = [
  {
    name: "Game Launcher",
    fullName: "Game Launcher",
    urlName: "game-launcher",
    language: "React",
    github: "https://github.com/maky1x/gamelauncher",
    img: "game-launcher256.png",//
    mobileImg: "game-launcher128.png",//
    downloadLink: "",//
    downloadSize: "",
    description: [
      "For this Game Launcher I've utilized React and NodeJS. Accessing a third-party API, the launcher is able to dynamically render information for the tabs within the project.",
      "It showcases a plethora of information regarding each game such as: prices, ratings, similar games as well as descriptions tied to each product.",
      "Tools I had access to, such as the Steam and RAWG API, made the development significantly smoother by implementing their data."
    ],
    libs: [
    ],
    extras: [
      {
        img: "game-launcher-extra1.png",//
        text: "At its current state, it serves only as a catalogue for presented titles, but with additional work it could evolve into a full-on platform as well.",
      },
      {
        img: "game-launcher-extra2.png",//
        text: ""
      }
    ],
    serbian: {
      name: "Pokretač igara",
      fullName: "Pokretač igara",
      description: [
        "Za ovaj Pokretač igara upotrebio sam React i NodeJS. Uz pristup API-a treće strane, pokretač je u mogućnosti da dinamično prikazuje informacije za prozore u projektu.",
        "Ovo demonstrira preobilje informacija za svaku pojedinačnu igru, kao što su: cene, ocene, slične igre kao i opis vezan za svaki element.",
        "Alatke kojima sam pristupao, kao što su Steam i RAWG API, su pomogle u glatkoj implementaciji njihovih podataka."
      ],
      extras: [
        {
          img: "game-launcher-extra1.png",//
          text: "U trenutnom obliku, projekat služi samo kao katalog za prezentovane naslove, ali sa dodatnim radom mogao bi da preraste u potpuno funkcionalnu platformu."
        },
        {
          img: "game-launcher-extra2.png",//
          text: ""
        }
      ]
    },
  },
  {
    name: "Fractal Creator",
    fullName: "Fractal Creator",
    urlName: "mandelbrot-set",
    language: "C++",
    github: "https://github.com/maky1x/MandelbrotSetFractal",
    img: "mandelbrot256.png",
    mobileImg: "mandelbrot128.png",
    downloadLink: "https://drive.google.com/file/d/1sWzu8TT6gRykL5ExGBWeQZhB9B2r89yl/view",
    downloadSize: "2 MB",
    description: [
      "This C++ project, showcased in the provided code, creates and renders captivating Mandelbrot set fractals.",
      "The heart of the project is the FractalCreator class and it takes user-defined width and height parameters to set the resolution of the resulting fractal image.",
      "The Mandelbrot class calculates the number of iterations required to determine if a complex number belongs to the Mandelbrot set.",
      "This project allows you to create captivating Mandelbrot set fractals with customizable resolutions, colors, and zoom levels.",
    ],
    libs: [
    ],
    extras: [
      {
        img: "mandelbrot_extra1.png",
        text: "Whether you're a mathematics enthusiast, an artist seeking inspiration, or simply curious about the beauty hidden within numbers, the Mandelbrot set offers a journey of discovery.",
      },
      {
        img: "mandelbrot_extra2.png",
        text: "Exploring the Mandelbrot set unveils patterns where art and mathematics come together in a visually engaging journey."
      }
    ],
    serbian: {
      name: "Kreator Fraktala",
      fullName: "Kreator Fraktala",
      description: ["Ovaj C++ projekat, prikazan u priloženom kodu, kreira i prikazuje Mandelbrot set fraktale.",
        "Srce projekta je klasa FractalCreator i uzima korisnički definisane parametre za širinu i visinu kako bi postavila rezoluciju rezultirajuće fraktalne slike.",
        "Klasa Mandelbrot izračunava broj iteracija potrebnih za određivanje da li broj pripada Mandelbrot setu.",
        "Ovaj projekat vam omogućava da kreirate zadivljujuće Mandelbrot set fraktale sa prilagodljivim rezolucijama, bojama i nivoima zumiranja.",
      ],
      extras: [
        {
          img: "mandelbrot_extra1.png",
          text: "Bilo da volite matematiku, tražite inspiraciju kao umetnik ili jednostavno ste znatiželjni zbog lepote skrivene u brojevima, Mandelbrot set pruža putovanje istraživanja.",
        },
        {
          img: "mandelbrot_extra2.png",
          text: "Istraživanjem Mandelbrot seta otkrivaju se obrasci gde se umetnost i matematika stapaju u putovanju."
        }
      ]
    },
  },


  {
    name: "Particle Explosion",
    fullName: "Particle Explosion",
    urlName: "particle-explosion",
    language: "C++",
    github: "https://github.com/maky1x/SDL-App",
    img: "particle256.png",
    mobileImg: "particle128.png",
    downloadLink: "https://drive.google.com/file/d/1iVDrLHIwGjpfITCRcg4PdUBbnGz2VIIl/view?usp=sharing", // ^
    downloadSize: "6 KB", // ^
    description: [
      "This C++ project, driven by the Simple DirectMedia Layer (SDL), introduces you to a particle explosion.",
      "Each particle follows its path, resulting in dynamic patterns.",
      "Whether you're interested in tech, art, or simply appreciate beautiful visuals, this particle explosion is sure to leave an impression.",
    ],
    libs: [
      {
        name: "SDL",
        url: "https://www.libsdl.org/"
      }
    ],
    extras: [
      {
        img: "particle_extra1.png",
        text: "In this image, you'll find the project's artistic touch, with particles moving gracefully and adding vivid colors to the screen.",
      },
      {
        img: "particle_extra2.png",
        text: ""
      },
    ],
    serbian: {
      name: "Eksplozija Cestica",
      fullName: "Eksplozija Cestica",
      description: [
        "Ovaj C++ projekat, pokretan Simple DirectMedia Layer (SDL), predstavlja vam eksploziju čestica.",
        "Svaka čestica prati svoju putanju, stvarajući dinamične obrasce.",
        "Bez obzira da li vas zanima tehnologija, umetnost ili jednostavno cenite lepe slike, ova eksplozija čestica sigurno će ostaviti utisak."
      ],
      extras: [
        {
          img: "particle_extra1.png",
          text: "Na ovoj slici ćete pronaći umetnički dodir projekta, sa česticama koje se graciozno kreću i dodaju žive boje ekranu.",
        },
        {
          img: "particle_extra2.png",
          text: ""
        },
      ]
    },
  },


];