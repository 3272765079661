import React, { createContext, useState } from "react";

export const ReferenceDataContext = createContext();

export const ContextProvider = ({ children }) => {
  const [language, setLanguage] = useState(false);
  return (
    <ReferenceDataContext.Provider value={{ language, setLanguage }}>
      {children}
    </ReferenceDataContext.Provider>
  );
};
