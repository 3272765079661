import { Header } from "./Header/Layout/Header";
import { Home } from "./Pages/Home/Layout/Home";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { Projects } from "./Pages/Projects/Layout/Projects";
import { About } from "./Pages/About/Layout/About";
import { ProjectInfo } from "./Pages/ProjectInfo/Layout/ProjectInfo";
import { createGlobalStyle } from "styled-components";
import InterBold from "./Fonts/Inter-Bold.ttf";
import Inter from "./Fonts/Inter-Regular.ttf";
import InterMedium from "./Fonts/Inter-Medium.ttf";
import InterSemiBold from "./Fonts/Inter-SemiBold.ttf";
import { ContextProvider } from "./Data/ContextProvider";
import { PageNotFound } from "./Pages/Home/Layout/PageNotFound";

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: "Inter";
    src: local("Inter-Regular"), url(${Inter}) format("truetype");
  }

  @font-face {
    font-family: "Inter Medium";
    src: local("Inter-Medium"), url(${InterMedium}) format("truetype");
  }

  @font-face {
    font-family: "Inter SemiBold";
    src: local("Inter-SemiBold"), url(${InterSemiBold}) format("truetype");
  }

  @font-face {
    font-family: "Inter Bold";
    src: local("Inter-Bold"), url(${InterBold}) format("truetype");
  }
`;



function App() {

  return (
    <Router>
      <GlobalStyle />
      <ContextProvider>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/about" element={<About />} />
          <Route path="/projects/:name" element={<ProjectInfo />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </ContextProvider>
    </Router>
  );
}

export default App;
