import styled from "styled-components";
import { Button as HeaderButton } from "../../../Header/SC/HeaderSC";
import { Link as LinkR } from "react-router-dom";
import { Box } from "@mui/material";

export const Container = styled(Box)`
  max-width: 100%;
  height: 91vh;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 824px) {
    width: 100%;
  }
`;

export const PContainer = styled(Container)`
  font-size:2rem;
  flex-direction:column;
    text-align:center;
  @media(max-width:824px) {
    font-size:1.5rem;
    width: 90%;
    margin: 0 auto 0 auto;
  }
`

export const Welcome = styled.div`
  font-size: 4rem;
  font-family: Inter Bold;
  @media (max-width: 824px) {
    display: none;
  }
`;

export const WelcomeMobile = styled(Welcome)`
  display: none;
  @media (max-width: 824px) {
    display: block;
    font-size: 3rem;
  }
`;

export const HeroText = styled.div`
  display: flex;
  width: 100%;
  height: 10vh;
  flex-direction: column;
  width: fit-content;
  text-align: center;
  margin-bottom: 100px;
  font-size: 2rem;
  font-family: Inter;
  @media (max-width: 824px) {
    font-size: 1.5rem;
  }
`;

export const Button = styled(HeaderButton)`
  padding: 10px 70px;
  width: fit-content;
  border: 1px solid white;
  border-radius: 5px;
  transition: all 150ms;
  &:hover {
    background-color: #e2e7f0;
    color: #1a202c;
  }
`;

export const Link = styled(LinkR)`
  display: flex;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
`;
