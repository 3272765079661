import React from "react";
import { Button, Link, PContainer } from "../SC/HomeSC";

export const PageNotFound = () => {
  return (
    <PContainer>
      We couldn't find the page you were looking for.
      <Link to="/" ><Button>Home</Button></Link>
    </PContainer>
  );
};
