import React, { useContext, useState } from "react";
import {
  Card,
  Desc,
  Gradient,
  HeroText,
  Link,
  MobileName,
  Name,
} from "../SC/ProjectsSC";
import "../../../Animations.css";
import { ReferenceDataContext } from "../../../Data/ContextProvider";

export const ProjectCard = ({ data }) => {
  const [hover, setHover] = useState(false);
  const { language } = useContext(ReferenceDataContext);
  return (
    <Link to={`./${data.urlName}`} state={{ data: data }}>
      <Card
        img={data.img}
        mobileImg={data.mobileImg}
        sx={{
          border: hover ? "3px solid #319deb" : "3px solid #e2e7f0",
          scale: hover ? "1.2" : "1.0",
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <Gradient sx={{ opacity: hover ? 0.5 : 1 }} />
        <HeroText
          sx={{
            animation: hover
              ? "project-text-in 0.15s forwards"
              : "project-text-out 0.15s",
          }}
        >
          <Desc
            sx={{
              animation: hover ? "desc-out 0.1s forwards" : "desc-in 0.1s",
            }}
          >
            {data.language}
          </Desc>
          <Name
            sx={{
              color: hover ? "#319deb" : "#e2e7f0",
              fontSize: hover ? "1.8rem" : "1.3rem",
            }}
          >
            {language ? data.serbian.name : data.name}
          </Name>
          <MobileName
            sx={{
              color: hover ? "#319deb" : "#e2e7f0",
              fontSize: hover ? "1.4rem" : "0.9rem",
            }}
          >
            {language ? data.serbian.name : data.name}
          </MobileName>
        </HeroText>
      </Card>
    </Link>
  );
};
// CALCULATE TITLE POSITION ON PROJECTS PAGE WITH @MEDIA AND CARD SIZE
