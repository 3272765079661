import React, { useContext } from "react";
import { Cards, Title } from "../SC/ProjectsSC";
import { ProjectCard } from "./ProjectCard";
import { data } from "../../../Data/data";
import { ReferenceDataContext } from "../../../Data/ContextProvider";

export const Projects = () => {
  const {language } = useContext(ReferenceDataContext);
  return (
    <>
      <Title>{language ? "Moji Projekti" : "Personal Projects"}</Title>
      <Cards>
        {data.map((project)=> (
          <ProjectCard key={project.urlName} data={project}/>
        ))
        }
      </Cards>
    </>
  );
};
