import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Container,
  Flag,
  Language,
  LanguageMobile,
  Profile,
  Spacing,
} from "../SC/HeaderSC";
import { Link, useLocation } from "react-router-dom";
import { ReferenceDataContext } from "../../Data/ContextProvider";
import { GitHub, Icons, Instagram, LinkedIn } from "../../Pages/About/SC/AboutSC";

export const Header = () => {
  const location = useLocation();
  const { language, setLanguage } = useContext(ReferenceDataContext);
  const [path, setPath] = useState(location.pathname);

  useEffect(()=>{
    const savedLang = JSON.parse(localStorage.getItem('language'));
    setLanguage(savedLang);
  },[setLanguage])

  useEffect(()=> {
    localStorage.setItem('language', JSON.stringify(language));
  },[language])

  useEffect(() => {
    setPath(location.pathname);
  }, [location]);

  return (
    <>
      <Container>
        <Language>
          <Flag
            onClick={() => setLanguage(true)}
            src={`${process.env.PUBLIC_URL}/rs.png`}
            alt="RS"
          />
          &nbsp;&nbsp;
          <Flag
            onClick={() => setLanguage(false)}
            src={`${process.env.PUBLIC_URL}/uk.png`}
            alt="UK"
          />
        </Language>
        <Spacing>
          <ButtonGroup>
            <Link to="/">
              <Button
                sx={{
                  color: path === "/" ? "#319deb" : "#e2e7f0",
                }}
              >
                {language ? "Pocetna" : "Home"}
              </Button>
            </Link>
            <Link to="/projects">
              <Button
                sx={{
                  color: path === "/projects" ? "#319deb" : "#e2e7f0",
                }}
              >
                {language ? "Projekti" : "Projects"}
              </Button>
            </Link>
            <Link to="/about">
              <Button
                sx={{
                  color: path === "/about" ? "#319deb" : "#e2e7f0",
                }}
              >
                {language ? "O meni" : "About"}
              </Button>
            </Link>
            <LanguageMobile onClick={() => setLanguage((lang) => !lang)}>
              {language ? "EN" : "RS"}
            </LanguageMobile>
          </ButtonGroup>

          <Profile>
          <Icons>
          <LinkedIn height={40} width={40}
            onClick={() =>
              window.open(
                "https://www.linkedin.com/in/maky1x/",
                "_blank",
                "noreferrer"
              )
            }
          />
          <GitHub height={40} width={40}
            onClick={() =>
              window.open("https://github.com/maky1x", "_blank", "noreferrer")
            }
          />
          <Instagram height={40} width={40}
            onClick={() =>
              window.open(
                "https://www.instagram.com/makineskovic/",
                "_blank",
                "noreferrer"
              )
            }
          />
        </Icons>
          </Profile>
        </Spacing>
      </Container>
    </>
  );
};
