import React, { useContext } from "react";

import {
  HeroText,
  Paragraph,
} from "../SC/AboutSC";
import { Title } from "../../Projects/SC/ProjectsSC";
import { ReferenceDataContext } from "../../../Data/ContextProvider";

export const About = () => {
  const { language } = useContext(ReferenceDataContext);
  return (
    <>
      <Title>{!language ? "About me" : "O meni"}</Title>
      <HeroText>
        {!language && (
          <>
            <Paragraph>
              Welcome to my web portfolio! I'm a results-driven high school student with a
              strong passion for front and back-end development.
            </Paragraph>
            <Paragraph>
              Starting off my career, I'm finding it very informative and fruitful to work on all sorts of projects, namely,
              Game launcher, a dynamic user interface for a game platform, which you can find in my projects page.
            </Paragraph>
            <Paragraph>
              I have had hands-on experience in the creation of a complete website for my high school.
            </Paragraph>
            <Paragraph>
              Beyond front and back-end, I've had numerous experiences with robotics. One of my favorite working projects was smart outlet, and it made me
              look more into the fusion between software development and hardware engineering.
            </Paragraph>
            <Paragraph>
              Through these experiences, I have not only accumulated valuable knowledge, but have also come to understand the value in precise
              and efficient work, as well as the coordination needed to work as a team.
            </Paragraph>
          </>
        )}
        {language && (
          <>
            <Paragraph>
              Dobrodošli na moj web portfolio! Ja sam srednjoškolac vođen rezultatima sa snažnom strašću za front i back-end razvojima.
            </Paragraph>
            <Paragraph>
              Na početku svoje karijere smatram da je veoma informativno i isplativo raditi na svim vrstama projekata, na primer, Pokretač igara,
              dinamički korisnički interfejs za platformu za igre, koji možete pronaći na stranici mojih projekata.
            </Paragraph>
            <Paragraph>
              Imao sam praktično iskustvo u izradi kompletne web stranice za svoju srednju školu.
            </Paragraph>
            <Paragraph>
              Osim front i back-enda, imao sam brojna iskustva sa robotikom. Jedan od mojih omiljenih projekata je bio pametna utičnica, i to me zainteresovalo za spoj
              između razvoja softvera i hardverskog inženjeringa.
            </Paragraph>
            <Paragraph>
              Kroz ova iskustva, ne samo da sam stekao dragoceno znanje, već sam i shvatio vrednost preciznog i efikasnog rada, kao i koordinaciju potrebnu za timski rad.
            </Paragraph>
          </>
        )}
      </HeroText>
    </>
  );
};
